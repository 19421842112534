<template>
  <div id="app">
    <div class="index-bg">
      <img
        class="bgm"
        v-if="debg"
        :style="{ height: key == 'about' ? '100vh' : 'auto' }"
        :src="debg"
      />
      <div class="content">
          <el-header class="header" :style="{background:'#fff'}" >
        <div style=" margin: 0 auto;display: flex; align-items: center;">

          <img
          @click="$router.push({path:'/home'})"
           src="@/assets/logo.png" alt="" style="width: 80px;margin-left: 100px;"/>
          <router-link to="/Home">首页</router-link> 
          <router-link to="/Cartoon">漫画中心</router-link>
          <router-link to="/music">音乐中心</router-link>
          <router-link to="/expression">表情中心</router-link>
          <router-link to="/user">用户纠纷处理</router-link>
          <router-link to="/me">自审制度</router-link>
          <router-link to="/about">客服中心</router-link>
          <router-link to="/login" v-show="!userinfo">登录注册</router-link>
          <div class="aa" v-show="userinfo" @click="out">退出登录</div>
          <div class="" v-if="userinfo" style="font-size: 14px; margin-right:20px;">用户名:{{userinfo.username}}</div>


        </div>

        </el-header>

        <div class="views">
          <router-view />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>
<script>
import Footer from "@/components/footer.vue";
import {getStorage} from "@/common/utils.js";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      userinfo:"",
      debg: "",
      key: "",
      bg: {
        Home: "",
        about: require("@/assets/image/contact.jpg"),
        music: require("@/assets/image/m-bg.png"),
        expression: require("@/assets/image/m-bg.png"),
        Cartoon: require("@/assets/image/solution.jpg"),
        login: require("@/assets/image/solution.jpg"),
      },
    };
  },
  watch: {
    "$route.path": function (newval) {
      this.key = newval.split("/")[1];
      this.debg = this.bg[this.key];
      if(getStorage('userinfo')){
        this.userinfo =getStorage('userinfo')
      }
    },
  },
  methods:{
    out(){
      localStorage.clear()
      this.userinfo = null
      this.$router.push({name:'login'})
    },
  }
};
</script>
<style>
	@import url("~@/assets/css/common.css");

* {
  margin: 0;
  padding: 0;
}
a,.aa{
  text-decoration-line:none;
  color: #000;
  margin: 0 30px;
  font-size: 18px;
  font-weight: bold;
  cursor:pointer;
  
}
a:hover{
  color: #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.index-bg {
  width: 100%;
}
.bgm {
  position: fixed;
  width: 100vw;

  left: 0;
  top: 0;
  z-index: 0;
}
.content {
  position: relative;
  z-index: 10;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 120px!important;
  text-align: start;
  z-index: 100000;
  display: flex;
  align-items: center;
  
}
.header img:hover{
  cursor:pointer;
}
.views{
  margin: 140px 0;
  padding: 0!important;
}
</style>
